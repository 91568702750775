<template>
  <div id="loginPage">
    <div class="row h-100">
      <div
        class="
          col-10
          offset-1
          col-sm-8
          offset-sm-2
          col-md-6
          offset-md-3
          col-lg-4
          offset-lg-4
          my-auto
        "
      >
        <transition name="fade" appear>
          <div :class="{failure: failure}">
            <div v-if="!unsupported" class="card bordernone bs-large">
              <div class="card-header bggreen text-white">
                <img
                  class="mw100"
                  src="@/assets/img/cube.png"
                  style="max-width: 50px; max-height: 50px"
                />
                <h4 class="mb0 ml10">Login</h4>
              </div>
              <div class="card-body">
                <h6 v-if="maintenanceMode" style="color: #e25353">
                  Under Maintenance, sorry for the inconvenience
                </h6>
                <h6 v-else-if="locked" style="color: #e25353">
                  We've temporarily locked your account after too many failed
                  requests. Please contact the Spacesaver IT dept.
                </h6>
                <form @submit.prevent="login()">
                  <!--
        <div class="form-group">
          <input class="form-control" v-model="username" placeholder="Username">
        </div>


        <div class="form-group">
          <input class="form-control" type="password" v-model="password" placeholder="Password">
        </div>
                -->
                  <small v-if="usedEmail" style="color: #6f6e6e"
                    >There is an issue with your username</small
                  >
                  <div class="input-group mb-3">
                    <input
                      v-if="maintenanceMode || locked"
                      disabled
                      class="form-control"
                      type="text"
                      v-model="username"
                      placeholder="Username"
                    />
                    <input
                      v-else
                      class="form-control"
                      type="text"
                      v-model="username"
                      placeholder="Username"
                      @change="checkUserLock()"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="fa fa-user fa-fw"></i>
                      </span>
                    </div>
                  </div>

                  <div class="input-group">
                    <input
                      v-if="maintenanceMode || locked"
                      disabled
                      class="form-control"
                      type="password"
                      v-model="password"
                      placeholder="Password"
                    />
                    <input
                      v-else
                      class="form-control"
                      :type="showPW ? 'text' : 'password'"
                      v-model="password"
                      placeholder="Password"
                      autocomplete="on"
                    />
                    <div class="input-group-append">
                       <span class="input-group-text">
                          <span v-show="showPW" v-on:click="hidePassWord">
                            <i class="fa fa-eye-slash password-icon" aria-hidden="true"></i>
                            
                          </span>
                            <span v-show="showPW == false" v-on:click="showPassWord">
                              <i class="fa fa-eye password-icon" aria-hidden="true"></i>
                            </span>
                        </span>
                    </div>
                  </div>

                  <div class="form-group mt15">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      sitekey="6Lfd0S0aAAAAAG5QikWeyx_7v6eNKg7zHrFoY0Tq"
                      :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>
                  </div>

                  <div class="form-group mt15">
                    <button
                      v-if="maintenanceMode || locked"
                      disabled
                      class="loginbtn"
                      style="width: 70.05px; height: 34px"
                    >
                      <span v-if="this.sending == false">Login</span>
                      <span v-else>
                        <looping-rhombuses-spinner
                          :animation-duration="1000"
                          :rhombus-size="6"
                          :color="'#fff'"
                        />
                      </span>
                    </button>

                    <button
                      v-else
                      class="loginbtn"
                      style="width: 70.05px; height: 34px"
                    >
                      <span v-if="this.sending == false">Login</span>
                      <span v-else>
                        <looping-rhombuses-spinner
                          :animation-duration="1000"
                          :rhombus-size="6"
                          :color="'#fff'"
                        />
                      </span>
                    </button>
                  </div>
<!--
                  <p>
                    <a
                      href="http://spacenet2.spacesaver.com/Admin/ForgotPassword.aspx"
                      target="_blank"
                      rel="noopener"
                      class="login-link"
                    >
                      <small>Forgot Password?</small>
                    </a>
                  </p>
               -->       
                  <p>
                    <router-link to="/forgot" class="login-link"
                      ><small>Forgot Password?</small></router-link
                    >
                  </p>
              
                  
               

                  <!--

        <p class="mb0"><small><a href="http://spacenet2.spacesaver.com/Admin/ForgotPassword.aspx" target="_blank">Forgot?</a></small></p>

        <p class="mb0"><small>{{feedback}}</small></p>
                --></form>

                <!--
      <button @click="showNavigation">ShowNav</button>
      <button @click="hideNavigation">HideNav</button>
              --></div>
            </div>

            <!-- unsupported browser -->
            <div v-else class="card mt200 bordernone bs-large">
              <div class="card-header bggreen text-white">
                <img
                  class="mw100"
                  src="@/assets/img/cube.png"
                  style="max-width: 50px; max-height: 50px"
                />
                <h4 class="mb0 ml10">Unsupported Browser</h4>
              </div>
              <div class="card-body">
                <h6>
                  It looks like you are using an outdated web browser. In order
                  to have a consistent experience, we suggest using a safer
                  browser that follows current web standards. Here are a few
                  good options!
                </h6>
                <hr />
                <ul class="browser-list">
                  <li>
                    <a
                      href="https://www.google.com/chrome/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="mw100"
                        src="@/assets/img/chrome.png"
                        style="max-width: 50px; max-height: 50px"
                        alt="Google Chrome"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.mozilla.org/en-US/firefox/new/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="mw100"
                        src="@/assets/img/firefox.png"
                        style="max-width: 50px; max-height: 50px"
                        alt="Mozilla Firefox"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.microsoft.com/en-us/edge"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="mw100"
                        src="@/assets/img/edge.png"
                        style="max-width: 50px; max-height: 50px"
                        alt="Microsoft Edge"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.apple.com/en_AU/downloads/safari"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="mw100"
                        src="@/assets/img/safari.png"
                        style="max-width: 50px; max-height: 50px"
                        alt="Apple Safari"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import VueRecaptcha from "vue-recaptcha";

//import shared from '@/state';

export default {
  name: "Login",
  components: {
    LoopingRhombusesSpinner,
    VueRecaptcha,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      title: process.env.VUE_APP_TITLE,
      username: "",
      password: "",
      error: null,
      failure: false,
      feedback: "",
      errors: [],
      sending: false,
      maintenanceMode: false,
      locked: false,
      unsupported: false,
      userIP: "",
      robot: false,
      usedEmail: false,
      showPW: false,
    };
  },

  methods: {
    refreshPage: function () {
      //this.$router.go();
      //refresh to get the newest update
      this.$forceUpdate();
      //this.$router.go(0);
      console.log("refreshed")
    },
    checkUserLock: function () {
      //console.log("Check to see of the user is locked");
    },
    showPassWord: function () {
      this.showPW = true;
    },
    hidePassWord: function () {
      this.showPW = false;
    },
    login() {
      localStorage.removeItem("SSCUSER");
      const check = this.checkEmail(this.username);

      //console.log(check);

      if (check == true) {
        this.usedEmail = true;
        //console.log("is an email");
      } else {
        this.usedEmail = false;
        //console.log("not an email");
      }

      if (this.robot && check == false) {
        //console.log("in here");
        this.sending = true;
        var postData = {
          username: this.username,
          password: this.password,
          ip: this.userIP,
        };

        this.$store
          .dispatch("login", postData)
          .then((response) => {
            //console.log("Response " + response);
            //this.$router.push("/home");
            //next({ name: "Login", query: { from: loginpath } });
            if (response) {
              if (this.$route.query.from) {
                //console.log("has query");
                this.$router.push({ path: this.$route.query.from });
              } else {
                //console.log("has no query");
                this.$router.push({ path: "/home" });
              }
              this.$store.commit("SET_TOKEN", localStorage.getItem("SSCUSER"));
              //this.$router.push("/home");
              this.sending = false;
            } else {
              this.sending = false;
              this.failure = true;
              setTimeout(() => this.failure = false, 2000);
            }
          })
          .catch((err) => {
            console.log("error " + err);
            this.sending = false;
            // this.failure = true;
            // setTimeout(() => this.failure = false, 2000);
            //setTimeout(function() { this.failure = false; }, 2000);
            /*
            this.$noty.error(
              "There seems to be an issue with your credentials."
            );
            */
            //this.feedback = "There seems to be an issue with your credentials.";
          });

        /*
        this.$store.commit('showNavigation');
        localStorage.setItem('sscuser', user);
        localStorage.setItem('isLoggedIn', '1');

        this.$router.push({
          name: 'Images',
        });
        */
      }
    },

    clearShake: function(){
      this.failure = false;
    },

    onVerify: function (response) {
      if (response) this.robot = true;
    },

    showNavigation() {
      this.$store.commit("showNavigation");
    },

    hideNavigation() {
      this.$store.commit("hideNavigation");
    },

    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    checkEmail(emailAddress) {
      var sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
      var sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
      var sAtom =
        "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
      var sQuotedPair = "\\x5c[\\x00-\\x7f]";
      var sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
      var sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
      var sDomain_ref = sAtom;
      var sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
      var sWord = "(" + sAtom + "|" + sQuotedString + ")";
      var sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
      var sLocalPart = sWord + "(\\x2e" + sWord + ")*";
      var sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
      var sValidEmail = "^" + sAddrSpec + "$"; // as whole string

      var reValidEmail = new RegExp(sValidEmail);

      return reValidEmail.test(emailAddress);
    },
    detectIE() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      //if (msie > 0 || navigator.userAgent.match(/Trident.*rv\:11\./)) {
      if (msie > 0 || msie == 11) {
        // If Internet Explorer, return version number
        //alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        this.unsupported = true;
      } // If another browser, return 0
      else {
        // alert("otherbrowser");
        return;
      }

      return false;
    },
  },

  computed: {
    //returns if the nav is open or closed
    navigationState() {
      return this.$store.state.nav;
    },
  },

  

  created() {
    
    this.refreshPage();
    localStorage.removeItem("SSCUSER");
    this.$store.commit("hideNavigation");
    this.toggleBodyClass("removeClass", "wsactive");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("SSCUSER");
    this.$store.commit("SET_ANNOUNCEMENTS_HOME");
    this.$store.commit("SET_FROM_THE_FORT_HOME");
    this.$store.commit("SET_FAQS_HOME");
    this.detectIE();
    //console.log(this.$router);
    //console.log(this.$route.query.from);
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.userIP = ip;
      });
    //console.log(window.innerWidth);
  },
  mounted() {

  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.card-header {
  display: flex;
  display: flex;
  align-items: center;
}

.login {
  display: none;
}
.mt200 {
  margin-top: 200px;
}
.bggreen {
  background: #00b4b4;
}
.green {
  color: #00b4b4;
}
.bordernone {
  border: none;
}
.mt15 {
  margin-top: 15px;
}

.bs {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.card-header {
  border-bottom: none;
}
.loginbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  background: #00b4b4;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 15px;
}
.mb0 {
  margin-bottom: 0 !important;
}

.ml10 {
  margin-left: 10px;
}
.login-link {
  cursor: pointer;
  color: #00b4b4;
  line-height: 1;
}

#loginPage {
  height: 100vh !important;
  width: 100vw !important;
}

#loginPage::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='414.5' height='715' viewBox='0 0 200 345'%3E%3Cdefs%3E%3Cfilter id='d' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12'%3E%3C/feGaussianBlur%3E%3C/filter%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='1' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23e1e3e4'/%3E%3Cstop offset='1' stop-color='%23dbdddf'/%3E%3C/linearGradient%3E %3CradialGradient id='b' gradientUnits='objectBoundingBox' cx='.5' cy='.5' r='1'%3E%3Cstop offset='0' stop-color='%23c2c3c5'/%3E%3Cstop offset='1' stop-color='%23a1a3a4'/%3E%3C/radialGradient%3E%3CradialGradient id='c' gradientUnits='objectBoundingBox' cx='.5%25' cy='.5%25' r='4'%3E%3Cstop offset='0' stop-color='%23dbdddf'/%3E%3Cstop offset='1' stop-color='%23c2c3c5'/%3E%3C/radialGradient%3E%3Csymbol id='e'%3E%3Cpolygon fill='url(%23a)' points='200 57.5 100 0 0 57.5 100 115'/%3E%3Cpolygon fill='url(%23b)' points='100 115 0 57.5 0 172.5 100 230'/%3E%3Cpolygon fill='url(%23c)' points='100 115 100 230 200 172.5 200 57.5'/%3E%3C/symbol%3E%3Csymbol id='f'%3E%3Cpolygon filter='url(%23d)' fill-opacity='0' points='200 172.5 113.5 107.5 100 0 87.5 107.5 0 172.5 100.5 129.5'/%3E%3C/symbol%3E%3C/defs%3E%3Cuse xlink:href='%23e' y='-57.5'/%3E%3Cuse xlink:href='%23e' x='100' y='115'/%3E%3Cuse xlink:href='%23e' x='-100' y='115'/%3E%3Cuse xlink:href='%23e' y='287.5'/%3E%3Cuse xlink:href='%23f' x='-100'/%3E%3Cuse xlink:href='%23f' x='100'/%3E%3Cuse xlink:href='%23f' y='172.5'/%3E%3Cuse xlink:href='%23f' y='-172.5'/%3E%3Cuse xlink:href='%23f' x='-100' y='345'/%3E%3Cuse xlink:href='%23f' x='100' y='345'/%3E%3Cuse xlink:href='%23f' x='200' y='172.5'/%3E%3Cuse xlink:href='%23f' x='200' y='172.5'/%3E%3C/svg%3E");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
}
.browser-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: scale;
}

.browser-list li {
  padding: 5px;
  transition: all 0.2s ease-in-out;
}
.browser-list li:hover {
  transform: scale(1.1);
}
.browser-list li:active {
  transform: scale(1.1);
}
.password-icon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.password-icon:hover {
  color: #00b4b4;
}
.password-icon:active {
  color: #00b4b4;
}
.failure {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}
</style>
